import React from "react"

const PageAboutSection = () => (
  <div className='bg-white pt-12 pb-16 md:min-h-screen'>
    <div className='w-11/12 md:max-w-screen-md mx-auto'>

      <h2 className="text-2xl md:text-3xl font-semibold">About Us</h2>

      <p className="font-body text-md md:text-md text-black mt-6">Welcome to Top Site Themes, the ultimate destination for finding the best themes, templates, plugins, and apps for your next website or online project. We are proud to present the best-curated directory of website resources, designed to cater to the diverse needs of individuals and businesses alike.</p>

      <p className="font-body text-md md:text-md text-black mt-6">At Top Site Themes, we understand the importance of a captivating and functional online presence. Whether you're a passionate blogger, an aspiring entrepreneur, or a seasoned professional, your website is your digital storefront, representing your unique identity and connecting you with your audience. That's why we're here to help you make a lasting impression with our wide range of meticulously selected themes, templates, plugins, and apps.</p>

      <p className="font-body text-md md:text-md text-black mt-6">Our team of experts has scoured the internet to handpick the very best designs and resources from talented creators across the globe. Each item in our directory has undergone a rigorous evaluation process to ensure it meets our high standards of quality, aesthetics, and functionality. We prioritize user experience, responsive design, and seamless integration, so you can find resources that not only look stunning but also perform flawlessly across all devices.</p>

      <p className="font-body text-md md:text-md text-black mt-6">Whether you're looking for a beautiful theme, a feature-rich plugin, or a powerful app, we've got you covered. Our directory features a diverse collection of resources spanning various industries, niches, and styles. From stunning website themes to robust plugins and cutting-edge apps, you'll find everything you need to bring your vision to life.</p>

      <p className="font-body text-md md:text-md text-black mt-6">At Top Site Themes, we believe in empowering our users with the tools and resources they need to succeed. Alongside our extensive directory, we offer insightful blog posts, videos, tutorials, and expert advice to guide you through the process of selecting, customizing, and optimizing your chosen resources. We are committed to providing you with a seamless and enjoyable experience, supporting you every step of the way on your journey to building an exceptional online presence.</p>

      <p className="font-body text-md md:text-md text-black mt-6">Thank you for choosing Top Site Themes as your trusted source for the best website templates, themes, plugins, and apps. Start exploring our directory today and unlock the limitless possibilities for your next online venture.</p>

      <h2 id="affiliate-links" className="text-2xl md:text-3xl font-semibold mt-12">Affiliate Links</h2>

      <p className="font-body text-md md:text-md text-black mt-6">Please note that the links to themes, templates, plugins, and apps on our website are affiliate links. This means that if you click on an affiliate link and make a purchase, we may earn a commission at no additional cost to you.</p>

      <p className="font-body text-md md:text-md text-black mt-6">We carefully select the themes, templates, plugins, and apps based on their quality, design, functionality, and user feedback. Our primary goal is to provide you with valuable recommendations that can enhance your website or project.</p>

      <p className="font-body text-md md:text-md text-black mt-6">The commissions we receive through affiliate links help support the maintenance and development of Top Site Themes. They allow us to continue providing curated lists, valuable content, and expand our offerings to include plugins and apps. However, please be assured that our affiliate partnerships do not influence the integrity of our recommendations. We prioritize the quality and suitability of themes, templates, plugins, and apps above any potential commissions.</p>

      <p className="font-body text-md md:text-md text-black mt-6">We greatly appreciate your support by using our affiliate links. If you have any questions or concerns about our affiliate disclosure, please feel free to <a href="mailto:contact@topsitethemes.com" className="hover:text-red-950 underline">contact us</a>.</p>
    </div>
  </div>
)

export default PageAboutSection