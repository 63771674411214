import * as React from "react"

import Layout from "../components/layout/layout"
import PageAboutHeroSection from "../components/sections/page-about-hero-section"
import PageAboutSection from "../components/sections/page-about-section"
import Seo from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <Seo 
        title="About"
        description="Discover the largest curated directory of top website templates, themes, plugins and apps at Top Site Themes."
      />

      <PageAboutHeroSection />

      <PageAboutSection />
    </Layout>
  )
}

export default AboutPage
