import React from "react"

const PageAboutHeroSection = () => (
  <div className="bg-gray-900 flex justify-center items-center px-4 pt-20 md:pt-28 pb-12">
    <div className='w-full max-w-screen-2xl flex flex-col md:flex-row items-center md:space-x-6 mx-auto'>
      <div className='w-full md:w-11/12 flex flex-col items-center justify-center mx-auto'>
        <h1 className='text-4xl md:text-5xl font-medium text-white text-center uppercase leading-tight mt-6 md:mt-0'>
          About Top Site Themes
        </h1>
      </div>
    </div>
  </div>
)

export default PageAboutHeroSection